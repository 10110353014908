import React, { lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import OAuth2RedirectHandler from "../Pages/Account/OAuth2RedirectHandler";
import jwt_decode from "jwt-decode";

const Login = lazy(() => import("../Pages/Account/Login"));

const ResetPassword = lazy(() => import("../Pages/ResetPassword"));

const Admin = lazy(() => import("../Pages/Admin/Admin"));
const AdminDashboard = lazy(() => import("../Pages/Admin/AdminDashboard"));
const AdminTenant = lazy(() => import("../Pages/Admin/AdminTenant"));

const routeLogic = (roles) => {
  if (roles != null) {
    if (roles === "ADMIN") {
      return (
        <>
          <Route exact path="/admin" element={<Admin />}></Route>
          <Route
            exact
            path="/admin/dashboard"
            element={<AdminDashboard />}
          ></Route>
          <Route
            exact
            path="/admin/tenant-list"
            element={<AdminTenant />}
          ></Route>
        </>
      );
    }
  }
};

function MainRouter(props) {
  // const dispatch = useDispatch();
  // const notification = useSelector((state) => state.notification);

  // useEffect();
  return (
    <Router>
      {/* <Notification
        type={notification.type}
        open={notification.Notification}
        message={notification.message}
        handleClose={() => {
          dispatch(offNotification());
        }}
      ></Notification> */}
      <React.Suspense fallback={<h1>Loding...</h1>}>
        <Routes>
          {/* {sessionStorage.getItem("token") != null &&
            (jwt_decode(sessionStorage.getItem("token"))?.role
              ? routeLogic(jwt_decode(sessionStorage.getItem("token"))?.role)
              : routeLogic(null))} */}
          {routeLogic("ADMIN")}
          <Route exact path="/login" element={<Login />}></Route>
          <Route
            exact
            path="/resetpassword"
            element={<ResetPassword />}
          ></Route>
          {/* <Route exact path="/test" element={<Test />}></Route> */}
          <Route
            path="/oauth2/redirect"
            element={<OAuth2RedirectHandler />}
          ></Route>
          <Route exact path="*" element={<Login />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default MainRouter;
